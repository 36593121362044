.about {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    

    &:after {
        content: '';
        height: 800px;
        width: 800px;
        background: $color-3;
        border-radius: 50%;
        position: absolute;
        bottom: -480px;
        right: 0px;
        z-index: -2;
        animation: homeAnim 26s infinite alternate;
    
        @media screen and (max-width: 650px){
          height: 400px;
          width: 400px;
          bottom: -270px;
        }
    
        @keyframes homeAnim {
          50% {
            transform: scale(0.8);
          }
          100% {
            transform: scale(1.1);
          }
        }
        @media screen and (max-width: 430px){
          display: none;
        }
      }
   

    &-content {
        background: rgba(223, 230, 233,0.5);
        display: grid;
        height: 550px;
        width: 72%;
        border-radius: 25px;
        grid-template-rows: 1fr 3fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: 
        "ab ab ab pic"
        "sk sk sk sk";

        @media screen and (max-width: 1300px){
            width: 60%;
          }
          @media screen and (max-width: 1150px){
            width: 54%;
            height: 600px;
          }
          @media screen and (max-width: 900px){
            grid-template-rows: 1fr 1fr 3fr;
            grid-template-areas: 
            "pic pic pic pic"
            "ab ab ab ab"
            "sk sk sk sk";

            height: 700px;
            width: 50%;
          }
          @media screen and (max-width: 600px){
            margin-top: 200px;
            width: 80%;
            height: 1000px;
            padding-top: 10px;
          }
    }


    &-main {
        padding: 10px 20px 10px 20px;
        grid-area: ab;
        // background: red;
        h1{
            margin-bottom: 10px;
            font-size: 38px;
            @media screen and (max-width: 1150px){
                font-size: 30px;
              }
         
        }
        p{
            line-height: 1.5rem;
            @media screen and (max-width: 1150px){
                font-size: 1rem;
                line-height: 1.2rem;
              }
            span{
                color: $color-2;
            }
        }
    }

    &-pictures {
        grid-area: pic;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // background: gray;
        
    img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        animation: quick-add_pulse 2s linear infinite; 
        @media screen and (max-width: 1150px){
            width: 100px;
            height: 100px;
          }

    @keyframes quick-add_pulse {
        0% {
            box-shadow: 0 0 0 0 	hsla(191, 100%, 46%,0.7);
        }

        70% {
            box-shadow: 0 0 0 0.8rem hsla(0,0%,100%,0);
        }

        100%{
            box-shadow: 0 0 0 0 hsla(0,0%,100%,0);
        }
        
    }
    }
    
    &-cv {
        background: $color-3;
        height: 50px;
        width: 120px;
        border-radius: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.2rem;
        cursor: pointer;
        transition: 0.2s;
      
        &:hover {
          letter-spacing: 1px;
          color: $color-2;
        }
      }
    

    }

    &-skills {
        grid-area: sk;
        // background: skyblue;
        h2{
            text-align: center;
            font-size: 36px;
            margin-top: 20px;
            @media screen and (max-width: 1150px){
                font-size: 20px;
              }
        }
        .skills-container {
            margin-top: 50px;
            display: flex;
            height: 70%;
            justify-content: space-around;
            align-items: flex-start;
            @media screen and (max-width: 600px){
                flex-direction: column;
               
                align-items: center;
                margin-top: 25px;
              }
            
            h3{
                font-size: 24px;
                font-family: Arial, Helvetica, sans-serif;
                // font-weight: 700;
                @media screen and (max-width: 1150px){
                    font-size: 16px;
                  }
            }
            li{
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 18px;
                line-height: 1.8rem;
                font-weight: 700;
                @media screen and (max-width: 1150px){
                    font-size: 12px;
                  }
                img {
                    width: 18px;
                    height: 18px;
                    @media screen and (max-width: 1150px){
                        width: 12px;
                        height: 12px;
                      }
                }
            }
        }
    }



}