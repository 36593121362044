.scroll-bottom {
  a {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    font-size: 3rem;
    color: rgb(184, 183, 183);
    transition: 0.25s;
    user-select: none;
    padding: 0 14px;

    &:hover {
      font-size: 4rem;
    }
  }

  .left {
    left: 15px;
  }

  .right {
    right: -24px;
  }
}
